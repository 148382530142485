// 引入 axios
import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development' ? "http://47.108.136.110" : "";
//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${baseURL}/portal-server${url}`,
        data: params,
    })
}
